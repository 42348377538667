import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(
  Object.assign({}, Wordpress2016, {
    baseFontFamily: "Noto Sans JP",
    headerFontFamily: ["Noto Sans JP", 'sans-serif'],
    bodyFontFamily: ["Noto Sans JP", 'sans-serif'],
    headerWeight: "600",
    fontWeight: '400'
  })
)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
